<template>
  <moe-page title="新增预售活动">
    <moe-card :autoHeight="true">
      <moe-steps :active="active" :stepsList="stepsList"></moe-steps>
      <template v-if="active === 0">
        <div class="df aic jcc mt-60 mb-10">
          <div class="mr-20">
            <moe-icon name="reminder" size="30px"></moe-icon>
          </div>
          <div class="font-16">
            <div class="mb-10 wsn">1.预售活动发布后，活动期间商品将不能下架及修改，活动不可中途关闭，请确认优惠信息设置无误。</div>
            <div class="wsn">2.活动发布后未到活动时间商品进入预热期，不可购买或支付定金。到达活动时间后自动开启预售。</div>
          </div>
        </div>
        <moe-form
          ref="presaleForm"
          :showBack="false"
          :model="presaleParams"
          :rules="rules"
          :defaultVerify="false">
          <el-form-item label="活动名称" prop="name">
            <el-input v-model.trim="presaleParams.name" placeholder="请输入活动名称" clearable maxlength="16"></el-input>
            <div class="color-info font-10">活动名称仅用于商家自己管理活动，不会显示给用户</div>
          </el-form-item>

          <el-form-item label="预售方式" prop="method">
            <moe-radio-group v-model="presaleParams.method" :radioBoxList="[{ label: '预售立减', value: 'REDUCED' }]"></moe-radio-group>
          </el-form-item>

          <el-form-item label="优惠级别" prop="level">
            <moe-radio-group :disabled="IS_EDIT" v-model="presaleParams.level" :radioBoxList="$moe_data.presaleLevel"></moe-radio-group>
            <div class="color-info font-10">活动创建后，优惠级别将无法修改</div>
          </el-form-item>

          <el-form-item label="定金支付时间" prop="depositStartTime">
            <el-date-picker
              :disabled="IS_EDIT"
              style="width: 100%;"
              placement="bottom-start"
              v-model="depositTime"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="定金支付开始时间"
              end-placeholder="定金支付结束时间"
              @change="(datetime) => ([presaleParams.depositStartTime, presaleParams.depositEndTime] = datetime || ['', ''])" />
          </el-form-item>

          <el-form-item label="尾款支付时间" prop="balanceStartTime">
            <el-date-picker
              :disabled="IS_EDIT"
              style="width: 100%;"
              placement="bottom-start"
              v-model="balanceTime"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="尾款支付开始时间"
              end-placeholder="尾款支付结束时间"
              @change="(datetime) => ([presaleParams.balanceStartTime, presaleParams.balanceEndTime] = datetime || ['', ''])" />
              <div class="color-info font-10">尾款支付时间截止未付尾款，自动关闭订单</div>
          </el-form-item>

          <template slot="tool">
            <el-button v-if="presaleParams.id" type="primary" icon="el-icon-right" :loading="presaleLoad" @click="handleStep(1, 'updatePresale')">下一步</el-button>
            <el-button v-else type="primary" icon="el-icon-thumb" :loading="presaleLoad" @click="addPresale()">{{ presaleLoad ? '保存中' : '保存并继续' }}</el-button>
          </template>
        </moe-form>
      </template>

      <template v-if="active === 1">
        <moe-describe-list title="" :col="2">
          <moe-describe-item label="活动名称"> {{ presaleParams.name }} </moe-describe-item>
          <moe-describe-item label="预售方式">预售立减</moe-describe-item>
          <moe-describe-item label="优惠级别"> {{ $moe_format.getPresaleLevel(presaleParams.level) }} </moe-describe-item>
          <moe-describe-item label="创建时间"> {{ createTime }} </moe-describe-item>
          <moe-describe-item label="定金支付时间">{{ presaleParams.depositStartTime }} 至 {{ presaleParams.depositEndTime }} </moe-describe-item>
          <moe-describe-item label="尾款支付时间"> {{ presaleParams.balanceStartTime }} 至 {{ presaleParams.balanceEndTime }}</moe-describe-item>
        </moe-describe-list>

        <div class="ml-30 font-20 fwb">设置商品优惠</div>

        <moe-form
          ref="presaleGoodsForm"
          class="auto-form"
          :showBack="false"
          :showTool="false"
          :model="form"
          :rules="rules"
          :defaultVerify="false">
          <div style="height: 700px;min-height: 700px;" class="df fdc">
            <moe-table
              :stripe="true"
              :key="itemKey"
              :numberShow="false"
              :data="form.configList"
              :mode="false"
              :showPage="false"
              :params="{ pageNum: 1 }"
              emptyText="请添加预售活动商品"
              rowKey="goodsId">
              <!-- 操作按钮 -->
              <el-form-item prop="configList" slot="tool">
                <el-button type="primary" icon="el-icon-circle-plus-outline" @click="form.showDialog = true;">添加商品</el-button>
              </el-form-item>

              <el-table-column label="商品信息" min-width="200">
                <template slot-scope="{ row }">
                  <div class="df aic">
                    <moe-image :src="row.coverUrl" width="80px" height="80px" />
                    <div style="text-align:left" class="ml-10">
                      <p class="fwb">{{ row.name }}</p>
                      <p class="font-12 color-info">商品编号: {{ row.goodsId }}</p>
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="单价" min-width="200">
                <template slot-scope="{ row }">
                  {{ `¥ ${row.minPrice}` }}{{ $moe_math.mathGreaterThan(row.maxPrice, row.minPrice) ? ` - ¥ ${row.maxPrice}` : '' }}
                </template>
              </el-table-column>

              <el-table-column label="预售优惠" width="200" v-if="presaleParams.level === 'GOOD'">
                <template slot-scope="{ row, $index }">
                  <el-form-item class="mt-20" :prop="`configList.${$index}.reduced`" :rules="rules.reduced">
                    <div class="df aic">
                      <div>立减</div>
                      <div class="df1 ml-5 mr-5">
                        <el-input
                          :disabled="row.id ? true : false"
                          type="text"
                          placeholder="请输入预售优惠"
                          :value="row.reduced"
                          @input="(value) => changeConfigListReduced(value, $index)" />
                      </div>
                      <div>元</div>
                    </div>
                  </el-form-item>
                </template>
              </el-table-column>

              <template v-if="presaleParams.level === 'SKU'">
                <el-table-column label="优惠方式" min-width="200">
                  <template slot-scope="{ row, $index }">
                    <div class="df fdc aic mt-20">
                      <el-form-item :prop="`configList.${$index}.presaleMinPrice`" :rules="rules.presaleMinPrice">
                        <div>预售立减</div>
                        <el-button
                          v-if="$moe_math.mathGreaterThan(row.presaleMinPrice, 0) && $moe_math.mathGreaterThan(row.presaleMaxPrice, 0)"
                          @click="queryShopGoodsById(row, $index)"
                          icon="el-icon-circle-check"
                          type="text">
                          预售价格设置
                        </el-button>
                        <el-button
                          v-else
                          @click="queryShopGoodsById(row, $index)"
                          icon="el-icon-thumb"
                          type="text">
                          预售价格设置
                        </el-button>
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="优惠前后价格" min-width="300">
                  <template slot-scope="{ row }">
                    <div class="df fdc aic">
                      <div class="line-through dib">原售价：{{ `¥ ${row.minPrice}` }}{{ $moe_math.mathGreaterThan(row.maxPrice, row.minPrice) ? ` - ¥ ${row.maxPrice}` : '' }}</div>
                      <div>预售价：{{ $moe_math.mathGreaterThan(row.presaleMinPrice, 0) ? `¥ ${row.presaleMinPrice}${ $moe_math.mathGreaterThan(row.presaleMaxPrice, row.presaleMinPrice) ? ` - ¥ ${row.presaleMaxPrice}` : '' }` : '-' }}</div>
                    </div>
                  </template>
                </el-table-column>
              </template>

              <template v-if="presaleParams.level === 'GOOD'">
                <el-table-column label="优惠前后价格" min-width="200">
                  <template slot-scope="{ row }">
                    <div class="line-through dib">原售价：{{ `¥ ${row.minPrice}` }}{{ $moe_math.mathGreaterThan(row.maxPrice, row.minPrice) ? ` - ¥ ${row.maxPrice}` : '' }}</div>
                    <div>预售价：{{ `¥ ${$moe_math.mathSub(row.minPrice, row.reduced)}` }}{{ $moe_math.mathGreaterThan($moe_math.mathSub(row.maxPrice, row.reduced), $moe_math.mathSub(row.minPrice, row.reduced)) ? ` - ¥ ${$moe_math.mathSub(row.maxPrice, row.reduced)}` : '' }}</div>
                  </template>
                </el-table-column>

                <el-table-column label="预付定金" width="200">
                  <template slot-scope="{ row, $index }">
                    <el-form-item class="mt-20" :prop="`configList.${$index}.deposit`" :rules="rules.deposit">
                      <div class="df aic">
                        <div>预付</div>
                        <div class="df1 ml-5 mr-5">
                          <el-input
                            :disabled="row.id ? true : false"
                            type="text"
                            placeholder="请输入预付定金"
                            :value="row.deposit"
                            @input="(value) => changeConfigListDeposit(value, $index)" />
                        </div>
                        <div>元</div>
                      </div>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column label="付款金额明细" width="200">
                  <template slot-scope="{ row, $index }">
                    <el-form-item :prop="`configList.${$index}.balancePayment`" :rules="rules.balancePayment">
                      <div class="df fdc aic mt-20">
                        <div>定金支付：{{ $moe_math.mathGreaterThan(row.deposit, 0) ? `￥ ${row.deposit}` : '-' }}</div>
                        <div>尾款支付：{{ `¥ ${$moe_math.mathSub(row.minPrice, row.balancePayment)}` }}{{ $moe_math.mathGreaterThan($moe_math.mathSub(row.maxPrice, row.balancePayment), $moe_math.mathSub(row.minPrice, row.balancePayment)) ? ` - ¥ ${$moe_math.mathSub(row.maxPrice, row.balancePayment)}` : '' }}</div>
                      </div>
                    </el-form-item>
                  </template>
                </el-table-column>
              </template>

              <el-table-column label="操作" width="200" fixed="right">
                <template slot-scope="{ row, $index }">
                  <el-button :disabled="row.id ? true : false" icon="el-icon-delete" size="small" type="danger" @click="handleDelete(row, $index)">撤出活动</el-button>
                </template>
              </el-table-column>
            </moe-table>
          </div>
        </moe-form>

        <div class="df aic jcc">
          <el-button type="primary" plain icon="el-icon-back" :loading="presaleLoad" @click="handleStep(0)">{{ presaleLoad ? '保存中' : '上一步' }}</el-button>
          <el-button type="primary" icon="el-icon-upload2" :loading="presaleLoad" @click="handleStep(3, 'updatePresaleConfigList')">{{ presaleLoad ? '保存中' : '提交' }}</el-button>
        </div>
      </template>

      <template v-if="active === 3">
        <moe-describe-list title="" :col="2">
          <moe-describe-item label="活动名称"> {{ presaleParams.name }} </moe-describe-item>
          <moe-describe-item label="预售方式"> 预售立减 </moe-describe-item>
          <moe-describe-item label="优惠级别"> {{ $moe_format.getPresaleLevel(presaleParams.level) }} </moe-describe-item>
          <moe-describe-item label="创建时间"> {{ createTime }} </moe-describe-item>
          <moe-describe-item label="定金支付时间">{{ presaleParams.depositStartTime }} 至 {{ presaleParams.depositEndTime }} </moe-describe-item>
          <moe-describe-item label="尾款支付时间"> {{ presaleParams.balanceStartTime }} 至 {{ presaleParams.balanceEndTime }}</moe-describe-item>
        </moe-describe-list>

        <div class="df aifs ml-40 mb-40">
          <el-button
            style="font-size: 26px;"
            @click="queryShopGoodsById(row, $index)"
            icon="el-icon-circle-check"
            type="text">
            "商品预售活动”设置成功！
          </el-button>
        </div>
        <div class="df aifs ml-40">
          <el-button type="primary" icon="el-icon-back" :loading="presaleLoad" @click="$moe_coordinator.navigateBack()">返回列表</el-button>
        </div>
      </template>
    </moe-card>

    <goodsDialog
      :showActivityRepel="true"
      :showDialog.sync="form.showDialog"
      :defaultSelectIds="form.configList && form.configList.length ? form.configList.map(({ goodsId }) => goodsId) : []"
      @close="handleClose">
    </goodsDialog>

    <moe-dialog :show="goodsListSpecForm.showDialog" title="SKU优惠设置" width="70%" @close="handleDialogClose()">
      <div class="dialog_content">
        <moe-form
          ref="goodsItemForm"
          class="default-form"
          :showBack="false"
          :showTool="false"
          :model="goodsListSpecForm"
          :rules="rules">
          <moe-table
            :key="itemKey"
            url=""
            :maxHeight="480"
            :data="goodsListSpecForm.list"
            :number-show="false"
            :mode="false"
            :params="{ pageNum: 1 }">
            <el-table-column label="商品编号" prop="goodsId" width="100" />

            <el-table-column label="SKU" min-width="200">
              <template slot-scope="{ row }">
                <div class="df aic jcc">
                  <moe-tag class="mr-5" v-for="(item, index) in row.specValueStr.split(',')" :key="index">{{ item }}</moe-tag>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="库存" prop="stock" width="100" />

            <el-table-column label="预售优惠" width="200">
              <template slot-scope="{ row, $index }">
                <el-form-item class="mt-20" :prop="`list.${$index}.reduced`" :rules="rules.reduced">
                  <div class="df aic">
                    <div>立减</div>
                    <div class="df1 ml-5 mr-5">
                      <el-input
                        :disabled="row.id ? true : false"
                        type="text"
                        placeholder="请输入预售优惠"
                        :value="row.reduced"
                        @input="(value) => changeConfigListReduced(value, $index)" />
                    </div>
                    <div>元</div>
                  </div>
                </el-form-item>
              </template>
            </el-table-column>

            <el-table-column label="优惠前后价格" min-width="200">
              <template slot-scope="{ row }">
                <div>
                  <div class="line-through dib">原售价：￥{{ row.salePrice }}</div>
                  <div>预售价：{{ $moe_math.mathGreaterThan(row.reduced, 0) ? `￥${$moe_math.mathSub(row.salePrice, row.reduced)}` : '-' }}</div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="预付定金" width="200">
              <template slot-scope="{ row, $index }">
                <el-form-item class="mt-20" :prop="`list.${$index}.deposit`" :rules="rules.deposit">
                  <div class="df aic jcc">
                    <div>预付</div>
                    <div class="df1 ml-5 mr-5">
                      <el-input
                        :disabled="row.id ? true : false"
                        type="text"
                        placeholder="请输入预售优惠"
                        :value="row.deposit"
                        @input="(value) => changeConfigListDeposit(value, $index)" />
                    </div>
                    <div>元</div>
                  </div>
                </el-form-item>
              </template>
            </el-table-column>

            <el-table-column label="付款金额明细" width="250">
              <template slot-scope="{ row, $index }">
                <el-form-item :prop="`list.${$index}.balancePayment`" :rules="rules.balancePayment">
                  <div class="df fdc aic mt-20">
                    <div>定金支付：{{ $moe_math.mathGreaterThan(row.deposit, 0) ? `￥${row.deposit}` : '-' }}</div>
                    <div>尾款支付：{{ `￥ ${$moe_math.mathSub(row.salePrice, row.balancePayment)}` }}</div>
                  </div>
                </el-form-item>
              </template>
            </el-table-column>
          </moe-table>
        </moe-form>
      </div>

      <template slot="footer">
        <el-button @click="handleDialogClose()">取消</el-button>
        <el-button type="primary" @click="handleConfirm()">确定</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
import goodsDialog from '@/views/ActivityManage/components/goodsDialog.vue';
export default {
  name: 'ActivityManagePresaleAdd',
  components: {
    goodsDialog
  },
  computed: {
    /** 新增 | 编辑 */
    IS_EDIT() {
      if (this.presaleParams.id) {
        return true
      } else {
        return false
      }
    },
  },
  data() {
    const verify = this.$moe_verify.verifyForm
    const checkDepositTime = (rule, value, callback) => {
      if (!value) {
        callback(new Error(`请选择定金支付时间`));
      } else {
        if (this.presaleParams.balanceStartTime && this.$moe_time.dateBefore({ startDate: this.presaleParams.balanceStartTime, endDate: this.presaleParams.depositStartTime })) {
          callback(new Error(`"尾款支付开始时间”必须大于"定金支付开始时间”`));
        } else if (this.presaleParams.balanceStartTime && this.$moe_time.dateBefore({ startDate: this.presaleParams.balanceStartTime, endDate: this.presaleParams.depositEndTime })) {
          callback(new Error(`"尾款支付开始时间”必须大于"定金支付结束时间”`));
        } else if (this.$moe_time.dateSame({ startDate: this.presaleParams.depositStartTime, endDate: this.presaleParams.depositEndTime })) {
          callback(new Error(`"定金支付结束时间”必须大于"定金支付开始时间”`));
        } else {
          callback();
        }
        callback();
      }
    }
    const checkBalanceTime = (rule, value, callback) => {
      if (!value) {
        callback(new Error(`请选择尾款支付时间`));
      } else {
        if (this.presaleParams.depositStartTime && this.$moe_time.dateBefore({ startDate: this.presaleParams.balanceStartTime, endDate: this.presaleParams.depositStartTime })) {
          callback(new Error(`"尾款支付开始时间”必须大于"定金支付开始时间”`));
        } else if (this.presaleParams.depositEndTime && this.$moe_time.dateBefore({ startDate: this.presaleParams.balanceStartTime, endDate: this.presaleParams.depositEndTime })) {
          callback(new Error(`"尾款支付开始时间”必须大于"定金支付结束时间”`));
        } else if (this.$moe_time.dateSame({ startDate: this.presaleParams.balanceStartTime, endDate: this.presaleParams.balanceEndTime })) {
          callback(new Error(`"尾款支付结束时间”必须大于"尾款支付开始时间”`));
        } else {
          callback();
        }
        callback();
      }
    }
    const checkReduced = (rule, value, callback) => {
      if (!value) {
        callback(new Error(`请输入预售优惠`));
      } else {
        if (this.presaleParams.level === 'GOOD') {
          let index = rule.fullField.split('.')[1];
          let findItem = this.form.configList[index];
          if (findItem && this.$moe_math.mathLessThanOrEqualTo(findItem.reduced, 0)) {
            callback(new Error(`预售优惠必须大于0元`));
          } else if (findItem && this.$moe_math.mathLessThanOrEqualTo(this.$moe_math.mathSub(this.$moe_math.mathSub(findItem.minPrice, findItem.reduced), findItem.deposit), 0)) {
            callback(new Error(`尾款支付必须大于0元`));
          } else {
            callback();
          }
        } else if (this.presaleParams.level === 'SKU') {
          let index = rule.fullField.split('.')[1];
          let findItem = this.goodsListSpecForm.list[index];
          if (findItem && this.$moe_math.mathLessThanOrEqualTo(findItem.reduced, 0)) {
            callback(new Error(`预售优惠必须大于0元`));
          } else if (findItem && this.$moe_math.mathLessThanOrEqualTo(this.$moe_math.mathSub(this.$moe_math.mathSub(findItem.salePrice, findItem.reduced), findItem.deposit), 0)) {
            callback(new Error(`尾款支付必须大于0元`));
          } else {
            callback();
          }
        } else {
          callback();
        }
      }
    }
    const checkDeposit = (rule, value, callback) => {
      if (!value) {
        callback(new Error(`请输入预付订金`));
      } else {
        if (this.presaleParams.level === 'GOOD') {
          let index = rule.fullField.split('.')[1];
          let findItem = this.form.configList[index];
          if (findItem && this.$moe_math.mathLessThanOrEqualTo(findItem.deposit, 0)) {
            callback(new Error(`预付定金必须大于0元`));
          } else if (findItem && this.$moe_math.mathLessThanOrEqualTo(this.$moe_math.mathSub(this.$moe_math.mathSub(findItem.minPrice, findItem.reduced), findItem.deposit), 0)) {
            callback(new Error(`尾款支付必须大于0元`));
          } else {
            callback();
          }
        } else if (this.presaleParams.level === 'SKU') {
          let index = rule.fullField.split('.')[1];
          let findItem = this.goodsListSpecForm.list[index];
          if (findItem && this.$moe_math.mathLessThanOrEqualTo(findItem.deposit, 0)) {
            callback(new Error(`预付定金必须大于0元`));
          } else if (findItem && this.$moe_math.mathLessThanOrEqualTo(this.$moe_math.mathSub(this.$moe_math.mathSub(findItem.salePrice, findItem.reduced), findItem.deposit), 0)) {
            callback(new Error(`尾款支付必须大于0元`));
          } else {
            callback();
          }
        } else {
          callback();
        }

      }
    }
    const checkBalancePayment = (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
        if (this.presaleParams.level === 'GOOD') {
          let index = rule.fullField.split('.')[1];
          let findItem = this.form.configList[index];
          if (findItem && this.$moe_math.mathLessThanOrEqualTo(this.$moe_math.mathSub(this.$moe_math.mathSub(findItem.minPrice, findItem.reduced), findItem.deposit), 0)) {
            callback(new Error(`尾款支付必须大于0元`));
          } else {
            callback();
          }
        } else if (this.presaleParams.level === 'SKU') {
          let index = rule.fullField.split('.')[1];
          let findItem = this.goodsListSpecForm.list[index];
          if (findItem && this.$moe_math.mathLessThanOrEqualTo(this.$moe_math.mathSub(this.$moe_math.mathSub(findItem.salePrice, findItem.reduced), findItem.deposit), 0)) {
            callback(new Error(`尾款支付必须大于0元`));
          } else {
            callback();
          }
        } else {
          callback();
        }
      }
    }
    return {
      stepsList: [{ title: '基本信息设置' }, { title: '设置商品优惠' }, { title: '完成预售活动' }],
      active: 0,
      presaleParams: {
        id: '',
        name: '',
        method: "REDUCED",    // 预售方式,可用值:REDUCED:预售立减
        level: "GOOD",        // 预售级别,可用值:GOOD:商品级别,SKU:SKU级别
        depositStartTime: "", // 定金支付开始时间
        depositEndTime: "",   // 定金支付结束时间
        balanceStartTime: "", // 尾款支付开始时间
        balanceEndTime: "",   // 尾款支付结束时间
      },
      createTime: '',
      oldPresaleParams: {},
      depositTime: [],
      balanceTime: [],
      rules: {
        name: verify.isExtent(['请输入活动名称', '长度为 2 ~ 16'], '2~16'),
        method: verify.isEmpty('请选择预售方式'),
        level: verify.isEmpty('请选择优惠级别'),
        depositStartTime: [{ required: true, validator: checkDepositTime, trigger: ['blur', 'change'] }],
        balanceStartTime: [{ required: true, validator: checkBalanceTime, trigger: ['blur', 'change'] }],
        configList: verify.isEmpty('请添加预售活动商品'),
        reduced: [{ required: true, validator: checkReduced, trigger: ['blur', 'change'] }],
        deposit: [{ required: true, validator: checkDeposit, trigger: ['blur', 'change'] }],
        balancePayment: [{ required: true, validator: checkBalancePayment, trigger: ['blur', 'change'] }],
        presaleMinPrice: verify.isEmpty('请设置优惠方式'),
      },
      presaleLoad: false,
      form: {
        showDialog: false,
        configList: [],
      },
      goodsListSpecForm: {
        showDialog: false,
        goodsId: '',
        list: [],
      },
      itemKey: Math.random(),
    }
  },
  methods: {
    handleStep(current, type) {
      switch (type) {
        case 'updatePresale':
          this.updatePresale(current);
          break;
        case 'updatePresaleConfigList':
          this.updatePresaleConfigList(current)
          break;
        default:
          this.active = current;
          break;
      }
    },
    /** 选择商品确认 */
    handleClose(selectList) {
      selectList.forEach((item) => {
        let findItem = this.form.configList.find(({ id, goodsId }) => item.id === id || item.id === goodsId)

        if (!findItem) {
          this.form.configList.push({
            ...item,
            configList: [],
            goodsId: item.id,
            id: '',
            deposit: '',            // GOOD级别-定金
            reduced: '',            // GOOD级别-预售价
            balancePayment: '',
            presaleMinPrice: '',    // SKU级别-最小预售价
            presaleMaxPrice: '',    // SKU级别-最大预售价
          })
        }
      })

      this.form.showDialog = false;
    },
    /** 预售优惠input */
    changeConfigListReduced(value, index) {
      if (this.presaleParams.level === 'GOOD') {
        let row = this.form.configList[index];
        row.reduced = value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/, '$1$2$3').replace(/^0+(\d)/, '$1').replace(/^\./, '0.').match(/^\d*(\.?\d{0,2})/g)[0] || '';
        if (!value) {
          return false;
        }
        try {
          if (this.$moe_math.mathLessThanOrEqualTo(this.$moe_math.mathSub(row.minPrice, row.reduced), 0)) {
            row.reduced = this.$moe_math.mathSub(this.$moe_math.mathSub(row.minPrice, row.deposit), 0.01);
          }
          row.balancePayment = this.$moe_math.mathAdd(row.reduced, row.deposit);
        } catch {
          console.log('error')
        }
        this.$refs['presaleGoodsForm'].validateField(`configList.${index}.deposit`);
        this.$refs['presaleGoodsForm'].validateField(`configList.${index}.balancePayment`);
      } else if (this.presaleParams.level === 'SKU') {
        let row = this.goodsListSpecForm.list[index];
        row.reduced = value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/, '$1$2$3').replace(/^0+(\d)/, '$1').replace(/^\./, '0.').match(/^\d*(\.?\d{0,2})/g)[0] || '';
        if (!value) {
          return false;
        }
        try {
          if (this.$moe_math.mathLessThanOrEqualTo(this.$moe_math.mathSub(row.salePrice, row.reduced), 0)) {
            row.reduced = this.$moe_math.mathSub(this.$moe_math.mathSub(row.salePrice, row.deposit), 0.01);
          }
          row.balancePayment = this.$moe_math.mathAdd(row.reduced, row.deposit);
        } catch {
          console.log('error')
        }
        this.$refs['goodsItemForm'].validateField(`list.${index}.deposit`);
        this.$refs['goodsItemForm'].validateField(`list.${index}.balancePayment`);
      }
    },
    /** 预付定金input */
    changeConfigListDeposit(value, index) {
      if (this.presaleParams.level === 'GOOD') {
        let row = this.form.configList[index];
        row.deposit = value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/, '$1$2$3').replace(/^0+(\d)/, '$1').replace(/^\./, '0.').match(/^\d*(\.?\d{0,2})/g)[0] || '';
        if (!value) {
          return false;
        }
        try {
          if (this.$moe_math.mathLessThanOrEqualTo(this.$moe_math.mathSub(row.minPrice, row.deposit), 0)) {
            row.deposit = this.$moe_math.mathSub(this.$moe_math.mathSub(row.minPrice, row.reduced), 0.01);
          }
          row.balancePayment = this.$moe_math.mathAdd(row.reduced, row.deposit);
        } catch {
          console.log('error')
        }
        this.$refs['presaleGoodsForm'].validateField(`configList.${index}.reduced`);
        this.$refs['presaleGoodsForm'].validateField(`configList.${index}.balancePayment`);
      } else if (this.presaleParams.level === 'SKU') {
        let row = this.goodsListSpecForm.list[index];
        row.deposit = value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/, '$1$2$3').replace(/^0+(\d)/, '$1').replace(/^\./, '0.').match(/^\d*(\.?\d{0,2})/g)[0] || '';
        if (!value) {
          return false;
        }
        try {
          if (this.$moe_math.mathLessThanOrEqualTo(this.$moe_math.mathSub(row.salePrice, row.deposit), 0)) {
            row.deposit = this.$moe_math.mathSub(this.$moe_math.mathSub(row.salePrice, row.reduced), 0.01);
          }
          row.balancePayment = this.$moe_math.mathAdd(row.reduced, row.deposit);
        } catch {
          console.log('error')
        }
        this.$refs['goodsItemForm'].validateField(`list.${index}.reduced`);
        this.$refs['goodsItemForm'].validateField(`list.${index}.balancePayment`);
      }
    },
    /** SKU优惠设置 dialog关闭 */
    handleDialogClose() {
      this.goodsListSpecForm.showDialog = false;
    },
    handleConfirm() {
      this.$refs['goodsItemForm'].validate(() => {
        let findItem = this.form.configList.find(({ goodsId }) => this.goodsListSpecForm.goodsId === goodsId);
        let findIndex = this.form.configList.findIndex(({ goodsId }) => this.goodsListSpecForm.goodsId === goodsId);
        if (findItem) {
          let configList = this.$moe_lodash.cloneDeepData(this.goodsListSpecForm.list);
          findItem.configList = configList;
          findItem.presaleMinPrice = configList.map(({ balancePayment }) => Number(balancePayment)).sort((a, b) => a - b)[0];
          findItem.presaleMaxPrice = configList.map(({ balancePayment }) => Number(balancePayment)).sort((a, b) => b - a)[0];
        }

        this.$refs['presaleGoodsForm'].validateField(`configList.${findIndex}.presaleMinPrice`);

        this.handleDialogClose();
      })
    },
    /** 删除添加的商品 */
    handleDelete({ name }, index) {
      this.$moe_layer.confirm(`您确定要删除当前选项"${name}”吗？`, () => {
        this.form.configList.splice(index, 1);
      });
    },
    /** 创建预售活动 */
    addPresale() {
      this.$refs['presaleForm'].validate(() => {
        this.presaleLoad = true;
        this.$moe_api.PRESALE_API.addPresale(this.presaleParams).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('新增预售活动成功');
            this.$router.replace({
              query: {
                id: data.result.id
              }
            })
            this.getPresaleDetail();
            this.handleStep(1);
          } else {
            this.$moe_msg.error(data.message);
          }
        }).finally(() => {
          this.presaleLoad = false;
        });
      })
    },
    /** 更新预售活动信息 */
    updatePresale(current) {
      this.$refs['presaleForm'].validate(() => {
        var { id, name, method, level, depositStartTime, depositEndTime, balanceStartTime, balanceEndTime } = this.oldPresaleParams;
        if (this.presaleParams.id !== id
          || this.presaleParams.name !== name
          || this.presaleParams.method !== method
          || this.presaleParams.level !== level
          || this.presaleParams.depositStartTime !== depositStartTime
          || this.presaleParams.depositEndTime !== depositEndTime
          || this.presaleParams.balanceStartTime !== balanceStartTime
          || this.presaleParams.balanceEndTime !== balanceEndTime) {
          this.$msgbox({
            title: '系统提示',
            message: `检测到您修改了预售活动信息,您确定要保存并继续吗？`,
            showCancelButton: true,
            confirmButtonText: '保存并继续',
            cancelButtonText: '取消并继续',
          }).then(() => {
            this.presaleLoad = true;
            this.$moe_api.PRESALE_API.updatePresale(this.presaleParams).then((data) => {
              if (data.code === 200) {
                this.oldPresaleParams = this.$moe_lodash.cloneDeepData(this.presaleParams);
                this.$moe_msg.success('修改预售活动成功');
                this.active = current;
              } else {
                this.$moe_msg.error(data.message);
              }
            }).finally(() => {
              this.presaleLoad = false;
            });
          }).catch(() => {
            this.presaleParams = this.$moe_lodash.cloneDeepData(this.oldPresaleParams);
            this.active = current;
          })
        } else {
          this.active = current;
        }
      });

    },
    /** 更新预售活动商品配置信息 */
    updatePresaleConfigList(current) {
      if (!this.form.configList.length) {
        this.$moe_msg.warning('请完善信息 !')
      }
      this.$refs['presaleGoodsForm'].validate(() => {
        const h = this.$createElement;
        this.$msgbox({
          title: '发布确认',
          message: h('div', null, [
            h('div', { class: 'lh-28' }, '1.预售活动发布后，活动期间商品将不能下架及修改，活动不可中途关闭，请确认优惠信息设置无误。'),
            h('div', { class: 'lh-28' }, '2.活动发布后未到活动时间商品进入预热期，不可购买或支付定金。到达活动时间后自动开启预售。')
          ]),
          showCancelButton: true,
          confirmButtonText: '确定发布',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = '请稍候...';
              let configList = [];
              if (this.presaleParams.level === 'GOOD') {
                configList = this.form.configList.map(({ goodsId, goodsItemId, id, reduced, deposit }) => {
                  return {
                    goodsId,
                    goodsItemId: goodsItemId || '', // 商品skuId(sku级别时必传)
                    id: id || '',
                    reduced: reduced || 0,          // 预付定金
                    deposit: deposit || 0,          // 预售立减
                  }
                })
              } else if (this.presaleParams.level === 'SKU') {
                configList = this.form.configList.map(({ configList }) => {
                  return configList.map(({ goodsId, goodsItemId, id, reduced, deposit }) => {
                    return {
                      goodsId,
                      goodsItemId: goodsItemId || '', // 商品skuId(sku级别时必传)
                      id: id || '',
                      reduced: reduced || 0,          // 预付定金
                      deposit: deposit || 0,          // 预售立减
                    }
                  })
                }).flat()
              }
              this.$moe_api.PRESALE_API.updatePresaleConfigList({
                configList,
                id: this.presaleParams.id,
                publish: true,
              }).then((data) => {
                if (data.code === 200) {
                  this.active = current;
                } else {
                  this.$moe_msg.error(data.message);
                }
              }).finally(() => {
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = '确定';
                done();
              })
            } else if (action === 'cancel') {
              done();
            }
          }
        })
      })
    },
    /** 查询预售活动详情 */
    getPresaleDetail() {
      if (this.$route.query.id) {
        this.$moe_api.PRESALE_API.getPresaleDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code === 200) {
            let { id, name, method, level, depositStartTime, depositEndTime, balanceStartTime, balanceEndTime, configList, createTime } = data.result;
            this.presaleParams = {
              id,
              name,
              method,
              level,
              depositStartTime,
              depositEndTime,
              balanceStartTime,
              balanceEndTime,
            }

            this.createTime = createTime;

            this.oldPresaleParams = this.$moe_lodash.cloneDeepData(this.presaleParams);

            this.depositTime = [depositStartTime, depositEndTime];
            this.balanceTime = [balanceStartTime, balanceEndTime];

            if (configList.length) {
              this.getQueryShopGoodsByIds(configList);
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    },
    /** 查询商品详情 */
    queryShopGoodsById(row, index) {
      this.$moe_api.GOODS_API.queryShopGoodsById({ id: row.goodsId }).then((data) => {
        if (data.code === 200) {
          this.goodsListSpecForm.goodsId = data.result.id;
          let goodsItem = this.$moe_lodash.cloneDeepData(data.result.goodsItem);
          if (this.form.configList[index].configList.length) {
            this.goodsListSpecForm.list = goodsItem.filter(({ delFlag }) => delFlag === 0).map((item) => {
              item.goodsItemId = item.id;
              let findItem = this.form.configList[index].configList.find(({ goodsItemId }) => item.goodsItemId === goodsItemId);
              return {
                ...item,
                deposit: findItem.deposit || '',
                reduced: findItem.reduced || '',
                balancePayment: this.$moe_math.mathAdd(findItem.reduced, findItem.deposit) || '',
                id: findItem.id || ''
              }
            })
          } else {
            this.goodsListSpecForm.list = goodsItem.filter(({ delFlag }) => delFlag === 0).map((item) => {
              return {
                ...item,
                deposit: '',
                reduced: '',
                balancePayment: '',
                goodsItemId: item.id,
                id: '',
              }
            })
          }
          this.goodsListSpecForm.showDialog = true;
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 根据商品ID获取商品列表 */
    getQueryShopGoodsByIds(goodsList) {
      //数组去重
      let unionByList = this.$moe_lodash.unionByList(goodsList, goodsList, 'goodsId')
      this.$moe_api.GOODS_API.getQueryShopGoodsByIds({ ids: unionByList.map(({ goodsId }) => goodsId).join(',') }).then((data) => {
        if (data.code === 200) {
          let result = data.result;
          switch (this.presaleParams.level) {
            case 'GOOD':
              this.form.configList = unionByList.map((item) => {
                let findGoodsItem = result.find(({ id }) => item.goodsId === id);
                return {
                  ...findGoodsItem,
                  goodsId: findGoodsItem.id,
                  ...item,
                  balancePayment: this.$moe_math.mathAdd(item.reduced, item.deposit)
                }
              })
              break;
            case 'SKU':
              this.form.configList = unionByList.map((item) => {
                let findGoodsItem = result.find(({ id }) => item.goodsId === id);
                let configList = goodsList.filter(({ goodsId }) => item.goodsId === goodsId).map((goodsListItem) => {
                  return {
                    ...goodsListItem,
                    balancePayment: this.$moe_math.mathAdd(goodsListItem.reduced, goodsListItem.deposit)
                  }
                })

                return {
                  ...findGoodsItem,
                  goodsId: findGoodsItem.id,
                  ...item,
                  configList: configList.length ? configList : [],
                  presaleMinPrice: this.$moe_math.mathSub(findGoodsItem.minPrice, configList.map(({ reduced }) => Number(reduced)).sort((a, b) => a - b)[0]),
                  presaleMaxPrice: this.$moe_math.mathSub(findGoodsItem.maxPrice, configList.map(({ reduced }) => Number(reduced)).sort((a, b) => b - a)[0])
                }
              })
              break;
          }
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    }
  },
  mounted() {
    this.getPresaleDetail();
  }
}
</script>

<style lang="scss">

</style>